import WasmController from "react-lib/frameworks/WasmController";
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";
import OperatingTreatmentList from "issara-sdk/apis/OperatingTreatmentList_apps_ORM";
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";
import OperatingDetailDetail from "issara-sdk/apis/OperatingDetailDetail_apps_ORM";
import OperativeNoteTemplateList from "issara-sdk/apis/OperativeNoteTemplateList_apps_ORM";
import OperativeNoteTemplateDetail from "issara-sdk/apis/OperativeNoteTemplateDetail_apps_ORM";
import OperatingDetailBasicInfo from "issara-sdk/apis/OperatingDetailBasicInfo_apps_ORM";
import OperativeImageList from "issara-sdk/apis/OperativeImageList_apps_ORM";
import OperativeImageDetail from "issara-sdk/apis/OperativeImageDetail_apps_ORM";
import TeamPrintDetail from "issara-sdk/apis/TeamPrintDetail_apps_ORM";
import NurseImplantCreate from "issara-sdk/apis/NurseImplantCreate_apps_ORM";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import FormPostOperation from "react-lib/apps/HISV3/ORM/FormPostOperation";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { adToBe, beToAd, formatISOtoBEDate } from "react-lib/utils/dateUtils";
import moment from "moment";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  selectedOrOrder?: any;
  django?: any;

  // sequence
  selectedPatient?: any;
  selectedEncounter?: any;
  ORPostOperationSequence?: {
    swab_status?: any;
    type_wound?: any;
    type?: any;
    teamDetail?: any;
    searchPostResult?: any;
    searchPostProcedures?: any;
    searchTem?: any;
    operatingDetail?: any;
    uploadImg?: any;
    nurseImplant?: any;
  } | null;
};

const ICDAPI: Record<string, any> = {
  ICD10: icd10KeyUp,
  ICD9CM: icd9cmKeyUp,
};

export const StateInitial: State = {
  // sequence
  ORPostOperationSequence: null,
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "getData") {
    const [operatingDetail, type, swabStatus, typeWound, nurseImplant] = await Promise.all([
      OperatingDetailDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.orDetailId,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }),
      ChoiceView.get({
        apiToken: controller.apiToken,
        params: {
          field: "type",
          model: "core.Procedure",
          name_as_id: "true",
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }),
      ChoiceView.get({
        apiToken: controller.apiToken,
        params: {
          field: "swab_instrument_status",
          model: "ORM.Team",
          name_as_id: "true",
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }),
      ChoiceView.get({
        apiToken: controller.apiToken,
        params: {
          field: "type_of_wound",
          model: "ORM.Team",
          name_as_id: "true",
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }),
      NurseImplantCreate.get({
        apiToken: controller.apiToken,
        pk: params.orDetailId,
      }),
    ]);

    const surgeryTeams: Record<string, any>[] = operatingDetail[0]?.surgery_teams || [];
    const mainTeam = surgeryTeams.find((team) => team.is_main);

    if (mainTeam && (mainTeam.post_op_pre_principal_diagnosis || []).length === 0) {
      mainTeam.post_op_pre_principal_diagnosis = mainTeam.pre_principal_diagnosis.map(
        (item: any) => ({ ...item, id: undefined })
      );
    }

    if (mainTeam && mainTeam.detail?.post_op_pre_diagnosis_remark === undefined) {
      mainTeam.detail.post_op_pre_diagnosis_remark = mainTeam.diagnosis_remark;
    }

    controller.setState({
      ORPostOperationSequence: {
        ...state.ORPostOperationSequence,
        operatingDetail: operatingDetail[0],
        swab_status: swabStatus[0],
        teamDetail: mainTeam,
        type: type[0],
        type_wound: typeWound[0],
        nurseImplant: nurseImplant[0],
      },
    });
  } else if (params.action === "saveData") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    let teamDetailData = state.ORPostOperationSequence?.teamDetail;

    const data = {
      pre_principal_diagnosis: teamDetailData.pre_principal_diagnosis,
      post_op_pre_principal_diagnosis: teamDetailData.post_op_pre_principal_diagnosis,
      chief_surgeon: teamDetailData.id,
      post_operating_order_item: params.post_operating_order_item,
      post_secondary_diagnosis: params.post_secondary_diagnosis,
      post_principal_diagnosis: [
        {
          ...teamDetailData.post_principal_diagnosis?.[0],
          detail: teamDetailData.post_principal_diagnosis?.[0]?.detail || "",
        },
      ],
      anesthesiologist_summary: teamDetailData?.anesthesiologist_summary,
      swab_instrument_status: teamDetailData?.swab_instrument_status,
      swab_instrument_status_label: teamDetailData.swab_instrument_status_label,
      anesthetist_summary: teamDetailData.anesthetist_summary,
      assistant_surgeons_summary: teamDetailData.assistant_surgeons_summary,
      blood_loss: teamDetailData?.blood_loss,
      circulating_nurse_summary: teamDetailData.circulating_nurse_summary,
      operating_detail: teamDetailData.operating_detail,
      operative_note: teamDetailData.operative_note,
      operative_note_id: teamDetailData.operative_note_id,
      operative_complication: teamDetailData.operative_complication, //* issue 69886
      post_implant: teamDetailData.post_implant,
      pre_secondary_diagnosis: teamDetailData.pre_secondary_diagnosis,
      specific_concern: teamDetailData.specific_concern,
      specimen: teamDetailData.specimen,
      tourniquet: teamDetailData.tourniquet,
      type_of_wound: teamDetailData.type_of_wound,
      detail: teamDetailData.detail,
    };

    const teamDetail = await TeamDetail.update({
      apiToken: controller.apiToken,
      pk: teamDetailData.id,
      data: data,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    if (teamDetail[0]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          teamDetail: teamDetail[0],
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: teamDetail[1] },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  } else if (params.action === "printData") {
    controller.handleEvent({
      message: "PrintOperativeNote",
      params: { ...params },
    });
  } else if (params.action === "searchPostTreatment") {
    const search = await OperatingTreatmentList.list({
      apiToken: controller.apiToken,
      params: {
        search: params.search,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    controller.setState({
      ORPostOperationSequence: {
        ...state.ORPostOperationSequence,
        searchPostResult: {
          data: search[0],
          index: params.index,
        },
      },
    });
  } else if (params.action === "searchICD") {
    var method = params.method;
    if (params.method === "medterm") {
      const search = await ICDAPI[params.icdType].post({
        method: method,
        apiToken: controller.apiToken,
        data: {
          medterm: params.search,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          searchPostProcedures: {
            data: search[0],
            index: params.index,
          },
        },
      });
    } else if (params.method === "icdterm") {
      const search = await ICDAPI[params.icdType].post({
        method: method,
        apiToken: controller.apiToken,
        data: {
          icdterm: params.search,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          searchPostProcedures: {
            data: search[0],
            index: params.index,
          },
        },
      });
    } else if (params.method === "icdcode") {
      const search = await ICDAPI[params.icdType].post({
        method: method,
        apiToken: controller.apiToken,
        data: {
          icdcode: params.search,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          searchPostProcedures: {
            data: search[0],
            index: params.index,
          },
        },
      });
    }
  } else if (params.action === "getTemData") {
    const temList = await OperativeNoteTemplateList.list({
      apiToken: controller.apiToken,
      params: {
        name: params.search,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    controller.setState({
      ORPostOperationSequence: {
        ...state.ORPostOperationSequence,
        searchTem: temList[0],
      },
    });
  } else if (params.action === "saveTemData") {
    const temCreate = await OperativeNoteTemplateList.create({
      apiToken: controller.apiToken,
      data: {
        name: params.name,
        note: params.note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, { action: "getTemData" });
  } else if (params.action === "updateTemData") {
    const temUpdate = await OperativeNoteTemplateDetail.update({
      pk: params.pk,
      apiToken: controller.apiToken,
      data: {
        name: params.name,
        note: params.note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, { action: "getTemData" });
  } else if (params.action === "deleteTemData") {
    const temDelete = await OperativeNoteTemplateDetail.delete({
      pk: params.pk,
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, { action: "getTemData" });
  } else if (params.action === "getUploadImg") {
    const imgList = await OperativeImageList.list({
      operative_note: params.operative_note,
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    if (imgList[0]?.items?.lenght === 0) {
      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          uploadImg: [],
        },
      });
    } else {
      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          uploadImg: imgList[0],
        },
      });
    }
  } else if (params.action === "saveUploadImg") {
    const imgCreate = await OperativeImageList.create({
      operative_note: params.operative_note,
      apiToken: controller.apiToken,
      data: {
        description: params.description,
        image: params.image,
        is_active: true,
        operative_note: params.operative_note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, {
      action: "getUploadImg",
      operative_note: params.operative_note,
    });
  } else if (params.action === "updateUploadImg") {
    const imgUpdate = await OperativeImageDetail.update({
      pk: params.pk,
      apiToken: controller.apiToken,
      data: {
        description: params.description,
        image: params.image,
        is_active: params.is_active,
        operative_note: params.operative_note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });
    Start(controller, {
      action: "getUploadImg",
      operative_note: params.operative_note,
    });
  }
};
