import WasmController from "react-lib/frameworks/WasmController";
import OperativeCountsList from "issara-sdk/apis/OperativeCountsList_apps_ORM";
import InitialOperativeCountsView from "issara-sdk/apis/InitialOperativeCountsView_apps_ORM";
import OperativeCountsView from "issara-sdk/apis/OperativeCountsView_apps_ORM";
import OperativeCountsDetail from "issara-sdk/apis/OperativeCountsDetailView_apps_ORM";
import OperativeCountsDetailList from "issara-sdk/apis/OperativeCountsDetailList_apps_ORM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import OperatingDetailPacking from "issara-sdk/apis/OperatingDetailPacking_apps_ORM";
import NurseNoteTemplate from "issara-sdk/apis/NurseNoteTemplate_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;

  // SearchBoxI
  searchedItemListWithKey?: any;

  selectedOrOrder?: any;
  // sequence
  ORCountsSequence?: {
    sequenceIndex?: string | null;

    // data
    items?: any[];
    next?: any;
    previous?: any;
    total?: number;

    resolveFrom?: {
      solve_method: any[];
      remark: string;
      operating_order_id: number;
    };
    methodOptions?: any[];
    instrumentOptions?: any[];
    // selectedOrItem?: string | null;
    selectedShift?: any;

    // packing adder
    packingTypeOptions?: any[];
    packingTypeSelected?: string;
    packingItemSelected?: string;
    packingLocationOptions?: any[];
    packingLocationSelected?: string;
    packingPieces?: string;
    packingRemark?: string;
    packingFormData?: any;

    packingItems?: any[];
  } | null;

  searchOrItems?: any[];
};

export const StateInitial: State = {
  selectedOrOrder: null,

  // sequence
  ORCountsSequence: {
    sequenceIndex: null,

    // data
    items: [],
    next: null,
    previous: null,
    total: 0,

    methodOptions: [],
    instrumentOptions: [],
    // selectedOrItem: null,
    selectedShift: null,

    // packing adder
    packingTypeOptions: [],
    packingTypeSelected: "",
    packingItemSelected: "",
    packingLocationOptions: [],
    packingLocationSelected: "",
    packingPieces: "",
    packingRemark: "",

    packingItems: [],
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["orPlanNImplPacking", {}], ["orPackingSite", {}], ["orReasonDeleteCountItem", {}]],
    },
  } as any);

  const state = controller.getState();

  controller.setState(
    {
      ORCountsSequence: {
        ...state.ORCountsSequence,
        sequenceIndex: "Action",
        methodOptions: [
          {
            key: "Search and report",
            value: "Search and report",
            text: "Search and report",
          },
          { key: "X-ray in OR", value: "X-ray in OR", text: "X-ray in OR" },
        ],
        instrumentOptions: [
          { key: "UNCOUNTED", value: "UNCOUNTED", text: "Uncounted" },
          { key: "COMPLETE", value: "COMPLETE", text: "Complete" },
          { key: "INCOMPLETE", value: "INCOMPLETE", text: "Incomplete" },
        ],
        packingTypeOptions: [
          { key: "1", value: "1", text: "Swab" },
          { key: "2", value: "2", text: "Sharp" },
          { key: "3", value: "3", text: "Instrument" },
        ],
        packingLocationOptions: state.masterOptions?.orPackingSite || [],
      },
    },
    () => {
      Action(controller, { action: "FETCH" });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "SET_OPTION") {
    controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        User_Nurse_Circulating_Surgeon__1_1:
          params.selectedShift?.circulating_nurses || [],
        User_Nurse_Scrub_Surgeon__1_1: params.selectedShift?.scrub_nurses || [],
        User_Nurse_Scrub_Surgeon__1_2: params.selectedShift?.scrub_nurses || [],
      },
    });
  }

  if (params?.action === "FETCH") {
    await getOperativeCountList(controller, params);
    const [formResp, formErr, formNet] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedOrOrder?.encounter,
        form_code: "SubCountsForm",
        form_version: "0.1",
        operating_order_id: state.selectedOrOrder?.id,
      },
    });

    if (formResp) {
      state = controller.getState();
      controller.setState({
        ORCountsSequence: {
          ...state.ORCountsSequence,
          resolveFrom: formResp ? formResp.data : {},
        },
      });
    }
    console.log("formResp", formResp);

    //FETCH Packing
    const [respNurseNote, errorNurseNote, networkNurseNote] =
      await NurseNoteTemplate.get({
        params: { nurse_note_temp_code: "nurse_note_packing" },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

    const [resp, error, network] = await OperatingDetailPacking.retrieve({
      pk: state.selectedOrOrder?.operating_detail,
      params: {},
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
    console.log("OperatingDetailPacking", resp, error);
    state = controller.getState();
    controller.setState({
      ORCountsSequence: {
        ...state.ORCountsSequence,
        packingItems: resp ? resp.packing_items : [],
        packingFormData: resp?.packing_form_data
          ? resp.packing_form_data?.data
          : respNurseNote,
      },
    });
  } else if (params?.action === "ADD_ITEM") {
    state = controller.getState();

    let data = {
      add_times: params.qty,
      item_id: params.item_id,
      operative_counts: params.operative_counts_id,
      non_sterile_count: 0,
      sterile_count: 0,
    };

    if (params.item) {
      data["item"] = params.item;
    }

    const [resp, error, network] = await OperativeCountsDetailList.create({
      params: {},
      data: data,
      apiToken: controller.apiToken,
    });
    console.log("error", params.card, error);
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
      });
    }
    await getOperativeCountList(controller, params);
    // controller.setState({
    //   ORCountsSequence: {
    //     ...state.ORCountsSequence,
    //   },
    // });
  } else if (
    ["UPDATE_NON_STERILE_COUNT", "UPDATE_STERILE_COUNT"].includes(
      params?.action
    )
  ) {
    let data = {
      action: params?.action,
      non_sterile_count: params.non_sterile_count,
      sterile_count: params.sterile_count,
    };

    const [resp, error, network] = await OperativeCountsDetail.patch({
      pk: params.operative_counts_id,
      params: {},
      data: data,
      apiToken: controller.apiToken,
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
      });
    }
    await getOperativeCountList(controller, params);
    // controller.setState({
    //   ORCountsSequence: {
    //     ...state.ORCountsSequence,
    //   },
    // });
  } else if (params?.action === "CREATE_SHIFT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "LOADING",
      },
    });
    state = controller.getState();
    const [resp, error, network] = await OperativeCountsList.create({
      params: {},
      data: {
        operating_detail: state.selectedOrOrder.operating_detail,
        instrument_status: "UNCOUNTED",
        circulating_nurses: [],
        scrub_nurses: [],
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "SUCCESS",
        },
      });
    }
    await getOperativeCountList(controller, params);
  } else if (params?.action === "EDIT_SHIFT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "LOADING",
      },
    });
    state = controller.getState();
    console.log("state.selectedShift", state.ORCountsSequence?.selectedShift);
    if (!state.ORCountsSequence?.selectedShift)
      return console.log("No selected shift");
    let data = {
      circulating_nurses:
        state.ORCountsSequence?.selectedShift?.circulating_nurses || [],
      scrub_nurses: state.ORCountsSequence?.selectedShift?.scrub_nurses || [],
      instrument_status:
        state.ORCountsSequence?.selectedShift?.instrument_status_cal,
      operating_detail: state.selectedOrOrder?.operating_detail,
      start_time: state.ORCountsSequence?.selectedShift?.start_time,
    };

    const [resp, error, network] = await OperativeCountsView.update({
      pk: state.ORCountsSequence?.selectedShift?.id,
      params: {},
      data: data,
      apiToken: controller.apiToken,
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "SUCCESS",
        },
      });
    }
    await getOperativeCountList(controller, params);
    // controller.setState({
    //   ORCountsSequence: {
    //     ...state.ORCountsSequence,
    //   },
    // });
  } else if (params?.action === "ACTION_COUNT") {
    if (typeof params.add_times !== "number" || !isFinite(params.add_times)) {
      state = controller.getState();
      controller.setState({
        errorMessage: { 
          ...state.errorMessage, 
          [params.card]: ["Add", "A valid integer is required."] 
        },
      });
      return;
    } else if (typeof params.non_sterile_count !== "number" || !isFinite(params.non_sterile_count)) {
      state = controller.getState();
      controller.setState({
        errorMessage: { 
          ...state.errorMessage, 
          [params.card]: ["Circulating", "A valid integer is required."] 
        },
      });
      return;
    } else if (typeof params.sterile_count !== "number" || !isFinite(params.sterile_count)) {
      state = controller.getState();
      controller.setState({
        errorMessage: { 
          ...state.errorMessage, 
          [params.card]: ["Scrub", "A valid integer is required."] 
        },
      });
      return;
    }
    delete params.item["nameButton"];

    const data = {
      action: params.sub_action,
      add_times: params.add_times,
      item: params.item,
      operative_counts: params.operative_counts_id,
      non_sterile_count: params.non_sterile_count,
      sterile_count: params.sterile_count,
    };

    const [resp, error, network] = await OperativeCountsDetail.patch({
      pk: params.counts_details_id,
      params: {},
      data: data,
      apiToken: controller.apiToken,
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
      });
    }
    await getOperativeCountList(controller, params);
  } else if (params?.action === "SAVE_REMARK") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });
    const data = {
      action: "SAVE",
      allowed_actions: [],
      data: {
        operating_order_id: state.selectedOrOrder?.id,
        solve_method: state.ORCountsSequence?.resolveFrom?.solve_method,
        remark: state.ORCountsSequence?.resolveFrom?.remark,
      },
      encounter: state.selectedOrOrder?.encounter,
      form_code: "SubCountsForm",
      form_name: "form_name",
      form_version: "0.1",
    };

    console.log("ACTION_COUNT ", data);

    const [resp, error, network] = await FormDataList.create({
      params: {},
      data: data,
      apiToken: controller.apiToken,
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    }
  } else if (params.action === "ADD_PACKING") {
    state = controller.getState();
    if (!state.ORCountsSequence?.packingItemSelected) return;

    let _items = state.ORCountsSequence?.packingItems || [];

    const _item = {
      item: state.ORCountsSequence.packingItemSelected?.id,
      item_name: state.ORCountsSequence.packingItemSelected?.name,
      site: state.ORCountsSequence?.packingLocationSelected,
      site_name: state.ORCountsSequence?.packingLocationOptions?.filter(
        (p: any) => p.value === state.ORCountsSequence?.packingLocationSelected
      )?.[0]?.text,
      quantity: state.ORCountsSequence?.packingPieces,
      remark: state.ORCountsSequence?.packingRemark,
    };
    _items.push(_item);
    controller.setState({
      ORCountsSequence: {
        ...state.ORCountsSequence,
        packingItems: _items,
        packingTypeSelected: "",
        packingItemSelected: "",
        packingLocationSelected: "",
        packingPieces: "",
        packingRemark: "",
      },
    });
  } else if (params.action === "EDIT_PACKING") {
    const siteOptions =
      [...state.ORCountsSequence?.packingLocationOptions] || [];
    const selectedSite = siteOptions?.filter((item: any) => {
      return item.value === state.ORCountsSequence?.packingLocationSelected;
    });

    let items: any[] = [...state.ORCountsSequence?.packingItems];
    items[params.index] = {
      ...items[params.index],
      type: state.ORCountsSequence.packingTypeSelected,
      item: state.ORCountsSequence.packingItemSelected?.id,
      item_name: state.ORCountsSequence.packingItemSelected?.name,
      site: state.ORCountsSequence?.packingLocationSelected,
      site_name: selectedSite?.[0]?.text,
      quantity: state.ORCountsSequence?.packingPieces,
      remark: state.ORCountsSequence?.packingRemark,
    };
    controller.setState({
      ORCountsSequence: {
        ...state.ORCountsSequence,
        packingItems: items,
        packingTypeSelected: "",
        packingItemSelected: "",
        packingLocationSelected: "",
        packingPieces: "",
        packingRemark: "",
      },
    });
  } else if (params.action === "SET_EDIT_PACKING_DATA") {
    controller.setState({
      ORCountsSequence: {
        ...state.ORCountsSequence,
        ...params.data,
      },
    });
  } else if (params?.action === "CONFIRM_PACKING") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });
    state = controller.getState();
    let packing_form_data = {
      action: "CONFIRM",
      data: {
        evaluation: state.ORCountsSequence?.packingFormData?.evaluation || "",
        evaluation_check: false,
        evaluation_detail: "",
        goal: state.ORCountsSequence?.packingFormData?.goal || "",
        nursing_diagnosis:
          state.ORCountsSequence?.packingFormData?.nursing_diagnosis || "",
        plan_and_implementation:
          state.ORCountsSequence?.packingFormData?.plan_and_implementation ||
          "",
      },
      doctor_order: state.selectedOrOrder?.id,
      encounter: state.selectedOrOrder?.encounter,
      form_code: "CardPacking",
      form_name: "Operative Packing",
      form_version: "0.1",
    };

    let packing_items = state.ORCountsSequence?.packingItems;

    const [resp, error, network] = await OperatingDetailPacking.update({
      pk: state.selectedOrOrder?.operating_detail,
      params: {},
      data: {
        packing_form_data: packing_form_data,
        packing_items: packing_items,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    }
    controller.setState({
      ORCountsSequence: {
        ...state.ORCountsSequence,
        packingItems: resp ? resp.packing_items : [],
      },
    });
    await getOperativeCountList(controller, params);
  } else if (params?.action === "DELETE_OPERATING_ITEM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "LOADING",
      },
    });

    const [resp, error, network] = await OperativeCountsView.delete({
      pk: params.operative_counts_id,
      params: { item: params.item, cancel_reason: params.cancel_reason },
      apiToken: controller.apiToken,
    });
    if (error) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "SUCCESS",
        },
      });

      params.onSuccess?.();
    }
    await getOperativeCountList(controller, params);
  } 
};

const getOperativeCountList = async (controller: any, params: any) => {
  console.log("getOperativeCount....1");
  let state = controller.getState();
  console.log("state.selectedOrOrder?.id", state.selectedOrOrder);
  const [resp, error, network] = await OperativeCountsList.list({
    params: {
      operating_detail: state.selectedOrOrder.operating_detail,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });
  state = controller.getState();
  controller.setState({
    ORCountsSequence: {
      ...state.ORCountsSequence,
      items: resp ? resp.items : [],
    },
  });

  if (resp?.items?.length === 0) {
    //INIT
    const [resp2, error2, network2] = await InitialOperativeCountsView.create({
      pk: state.selectedOrOrder?.operating_detail,
      params: {},
      data: { items: [] },
      apiToken: controller.apiToken,
    });
    if (error2) {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error2 },
      });
    } else {
      state = controller.getState();
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: "" },
      });
    }
    state = controller.getState();
    controller.setState({
      ORCountsSequence: {
        ...state.ORCountsSequence,
        items: resp2 ? resp2.items : [],
      },
    });
  }
};
